/* eslint-disable no-undef */
import { Route, route, Router } from "preact-router";
import RootRoute from "./routes/RootRoute";
import AuthenticatedRoute from "./routes/AuthenticatedRoute";
import NotfoundScene from "./screens/NotfoundScene";
import AccountRoute from "./routes/AccountRoute";
import UserMobx from "./mobx/UserMobx";
import { env } from "./config";

if (env.node !== "development") {
  //console.log = () => null;
  //console.error = () => null;
}

const App = () => {
  window.document.title = "ระบบล็อคอินกลาง";
  function handleRouter(e) {
    const allowed = UserMobx.onCheckLogin;
    const url = new URL(e.url, window.location.origin);
    const gotoParam = url.searchParams.get('goto');
    
    if (!allowed) {
      // If already on login page, don't trigger another redirect
      if (url.pathname.startsWith("/account/login")) {
        return;
      }
      if (url.pathname.includes("account")) {
        return route(e.url);
      } else {
        // Preserve goto parameter when redirecting to login
        if (gotoParam) {
          return route(`/account/login?goto=${gotoParam}`);
        }
        return route("/account/login");
      }
    } else {
      if (url.pathname.includes("/account/")) {
        return route(e.url);
      }
      
      if (gotoParam === "fixedasset") {
        UserMobx.onLogout("/account/login?goto=fixedasset");
        return;
      }
      
      return route(["/"].includes(url.pathname) ? "/main" : e.url);
    }
  }

  return (
    <div>
      <Router onChange={(e) => handleRouter(e)}>
        <AccountRoute path="/account/:rest*" />
        <AuthenticatedRoute path="/:rest*" login="/login" route={RootRoute} />
        <Route path="*" default component={NotfoundScene} />
      </Router>
    </div>
  );
};

export default App;
