import { message } from "antd";
import { observable, action, computed } from "mobx";
import { route } from "preact-router";
import { decryptParameter, encryptParameter } from "../functions";
import { KEY_STORAGE } from "../resources";
import { GET, GET_PROFILE, GET_MENU } from "../services";
import { getCookies, removeCookies, setCookies } from "../store/useCookies";
import { goToPageAuth } from "../functions/menu";

class UserMobx {
  @observable isLogin = true;
  @observable access_token = "";
  @observable user_id = "";
  @observable user_menu = "";
  @observable full_name = "";
  @observable dplus_id = "";

  constructor() {
    this.onInitial();
  }

  @action
  async onInitial() {
    try {
      const cookies = getCookies(KEY_STORAGE);
      if (cookies) {
        const decode = decryptParameter(cookies);
        const parse = JSON.parse(decode);
        const { access_token, user_id } = parse;
        this.access_token = access_token;
        let res = await GET(GET_PROFILE, parse.access_token);
        const { success, result } = res;
        if (success) {
          this.dplus_id = result.dplus_id;
          this.full_name = result.full_name;
        }
        await this.onFetchMenu(parse.access_token);
        this.user_id = user_id;
        this.isLogin = true;
        const queryParams = new URLSearchParams(window.location.search);
        const webID = queryParams.get("webID");
        if (webID) {
          await goToPageAuth(webID, true);
        } else {
          route("/main");
        } //TODO go to initial page
      } else {
        if (!window.location.href.split("/").includes("account")) {
          this.onLogout();
        }
      }
    } catch (error) {
      console.log(error);
      if (error && error.message) {
        message.error(error.message);
      }
      this.onLogout();
    }
  }

  @action
  setCheckLogin(data) {
    this.isLogin = data;
  }

  @action
  async onLogin(userInfo) {
    if (userInfo) {
      const { access_token } = userInfo;
      this.access_token = access_token;
      const obj = { ...userInfo };
      const hash = encryptParameter(obj);
      setCookies(KEY_STORAGE, hash);
      await this.onInitial();
    }
  }

  @action
  async onFetchMenu(token) {
    try {
      if (token) {
        let res = await GET(GET_MENU, token);
        if (res.success) {
          const menuList = res.result.map((val, i) => {
            return {
              ...val,
              key: i,
              menuName: val.name,
              icon: val.name,
              subMenu:
                val.sub_menu &&
                val.sub_menu.map((sub) => {
                  return {
                    ...sub,
                    key: sub.id,
                    menuName: sub.name,
                    icon: "Submenu",
                  };
                }),
            };
          });
          this.user_menu = menuList;
        }
      } else {
        this.onLogout();
      }
    } catch (error) {
      console.log(error);
      if (error && error.message) {
        message.error(error.message);
      }
      this.onLogout();
    }
  }

  @computed
  get onCheckLogin() {
    return !!getCookies(KEY_STORAGE);
  }

  @computed
  get getMenu() {
    return this.user_menu;
  }

  @computed
  get getProfile() {
    return this.full_name;
  }

  @computed
  get onGetMenu() {
    return [
      {
        id: "32d05f46-de9f-4023-b0ff-252151aaf8f3",
        ref: "LARK_SYSTEM",
        title: "Lark System",
      },
      {
        id: "2cefa1ec-e494-4f0b-9115-180862798a28",
        ref: "HR_PMS",
        title: "HR PMS",
      },
      {
        id: "e9855e52-be90-4ee1-a712-c94d1aa96bfe",
        ref: "TIME_ATTENDANCE",
        title: "Time Attendance",
      },
      {
        id: "5ebefbfd-f11e-4746-8b69-a7382715bbff",
        ref: "POWER_BI",
        title: "Power BI",
      },
      {
        id: "7ad3344e-a36c-4d62-95c7-94996becd622",
        ref: "SO_PAYMENT",
        title: "SO Payment",
      },
      {
        id: "38db2055-9d5c-4d08-939c-7441f0711548",
        ref: "SALEPLUS",
        title: "Saleplus",
      },
    ];
  }

  @computed
  get returnToken() {
    return this.access_token;
  }

  @computed
  get returnDplus_id() {
    return this.dplus_id;
  }

  @action
  async onLogout(goto="/account/login") {
    this.access_token = "";
    this.isLogin = false;
    this.user_id = "";
    await removeCookies(KEY_STORAGE);
    
    // Get current goto parameter
    const url = new URL(window.location.href);
    const currentGoto = url.searchParams.get('goto');
    
    if(goto === "/account/login") {
      // Preserve goto parameter if exists
      const loginUrl = currentGoto ? `/account/login?goto=${currentGoto}` : goto;
      setTimeout(() => route(loginUrl, { replace: true }), 200);
    } else {
      window.location.href = "http://localhost:8555"+goto;
    }
  }
}

export default new UserMobx();
